import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { useState } from "react"

const Switcher = ({ items, onSelect, selected, disabled }) => {
    const [selectedItem, setSelectedItem] = useState(selected.value)

    const handleChange = (event, value) => {
        setSelectedItem(value)

        onSelect(value)
    }

    return (
        <ToggleButtonGroup
            value={selectedItem}
            exclusive
            onChange={handleChange}
            aria-label='Platform'
            disabled={disabled}>
            {items.map(({ label, value, superText }, key) => {
                return (
                    <ToggleButton
                        key={key}
                        value={value}
                        className={`${value === selectedItem && "text-white"}`}
                        style={{
                            padding: "0.5rem",
                            borderRadius: "5px",
                            backgroundColor:
                                value === selectedItem && "#e87b0e",
                            border: "1px solid #dbdbdb",
                            fontSize: "0.9rem"
                        }}>
                        {label}
                        {superText && (
                            <sup className='font-md text-2xl'>{superText}</sup>
                        )}
                    </ToggleButton>
                )
            })}
        </ToggleButtonGroup>
    )
}

export default Switcher
